// Import Swiper styles
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

//UTILS
@import "styles/utility/_colors.scss";
@import "styles/utility/_font-sizes.scss";

// COMPONENTS
@import "./styles/components/admin-match.scss";
@import "./styles/components/match.scss";
@import "./styles/components/knock-out.scss";
@import "./styles/components/table.scss";
@import "./styles/components/admin-view.scss";
@import "./styles/components/admin-teams.scss";
@import "./styles/components/admin-groups.scss";
@import "./styles/components/admin-matches.scss";
@import "./styles/components/admin-locations.scss";
@import "./styles/components/loading-icon.scss";
@import "./styles/components/loading-icon-dantam.scss";
@import "./styles/components/match-update-dialog.scss";
@import "./styles/components/error.scss";
@import "./styles/components/frontpage.scss";
@import "./styles/components/match-details.scss";
@import "./styles/components/earlier-matches.scss";
@import "./styles/components/tournament.scss";
@import "./styles/components/slideshow-table.scss";
@import "./styles/components/match-list.scss";
@import "./styles/components/dantam.scss";

$pulje-container-height: 70px;
$tab-bar-height: 65px;

button {
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji";
}

@font-face {
  font-family: 'JoannaSansNova';
  src: url("./assets/fonts/JoannaSansNova.TTF") format("truetype");
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

body {
  background-color: $color-darker-grey;
}

.content-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: $pulje-container-height;
  padding-bottom: $tab-bar-height;
  background-color: $color-darker-grey;
}

.topbar-container {
  z-index: 2;
  height: $pulje-container-height;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100vw;
  background-color: $color-darker-grey;

  div.column-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

div.progress-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.tab-bar {
  display: flex;
  position: fixed;
  bottom: 0;
  width: 100vw;
  z-index: 2;

  background-color: rgb(49, 49, 49);
  height: $tab-bar-height;

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33.3%;
    height: 100%;
    background-color: rgb(17, 17, 17);
    border: 1px solid rgb(112, 112, 112);

    &:hover {
      cursor: pointer;
    }
  }
}

div.no-knockout-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  top: 30px;
  position: relative;
}

.bg {
  animation: slide 30s ease-in-out infinite alternate;
  background-image: linear-gradient(-60deg, #ffa528 50%, #08062c 50%);
  bottom: 0;
  left: -50%;
  opacity: 0.5;
  position: fixed;
  right: -50%;
  top: 0;
  z-index: -1;
}

.bg2 {
  animation-direction: alternate-reverse;
  animation-duration: 40s;
}

.bg3 {
  animation-duration: 50s;
}

.content {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 0.25em;
  box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  left: 50%;
  padding: 10vmin;
  position: fixed;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
}

h1 {
  font-family: monospace;
}

@keyframes slide {
  0% {
    transform: translateX(-25%);
  }

  100% {
    transform: translateX(25%);
  }
}