.reveal-text {
  position: absolute;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.show-team {
  opacity: 1;
}

.hide-team {
  opacity: 0;
}

.opacity-animation {
  animation: show 1s ease-in forwards;
  animation-delay: 0.7s;
  opacity: 0;

  @keyframes show {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
}

.shuffle-text-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 180px;
}

.white-dot {
  position: absolute;
  display: flex;
  width: 0.5rem;
  height: 0.5rem;
  background-color: white;
  border-radius: 50%;
  animation: moveRight 0.6s ease-in-out infinite alternate;
  box-shadow: 0 0 5px 2.5px #fff, 0 0 8px 5px rgb(0, 17, 255), 0 0 15px 5px #0ff;
}

@keyframes moveRight {
  from {
    left: 5%;
  }

  to {
    left: 90%;
  }
}

.animated-text {
  display: inline-block;
  font-size: 1rem;
  font-family: Arial, sans-serif;
  margin: 0 0px;
  color: rgba(112, 129, 255, 0.6);
}

/* * {
  font-family: JoannaSansNova !important;
} */
